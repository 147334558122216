import React, { useMemo } from 'react'
import { usePathname } from 'router'

import { Meta } from 'components/dataDisplay'

import useClientSeo from './util/useClientSeo'
import useStrapiSeo from './util/useStrapiSeo'


const GlobalMeta: React.FunctionComponent = () => {
  const pathname = usePathname()
  const strapiSeo = useStrapiSeo()
  const clientSeo = useClientSeo()

  const { title, description, keywords, canonicalURL, image, structuredData } = useMemo(() => ({
    title: strapiSeo?.metaTitle || clientSeo.title,
    description: strapiSeo?.metaDescription || clientSeo.description,
    keywords: strapiSeo?.keywords || clientSeo.keywords,
    canonicalURL: strapiSeo?.canonicalURL || pathname,
    image: strapiSeo?.metaImage?.data?.attributes.url || clientSeo.image,
    structuredData: strapiSeo?.structuredData,
  }), [ strapiSeo, clientSeo, pathname ])

  return (
    <Meta
      title={title}
      metaTitle={title}
      metaDescription={description}
      pathname={canonicalURL}
      image={image}
      withoutTitleTemplate
      keywords={keywords}
      structuralData={structuredData}
    />
  )
}


export default GlobalMeta
