import React from 'react'
import links from 'links'
import { type ModalComponentProps } from 'modals'

import { Modal } from 'components/feedback'
import messages from './messages'


const FraudModal: React.FC<ModalComponentProps> = () => (
  <Modal
    title={messages.modalTitle}
    text={messages.modalText}
    withCloseButton={false}
    overlayClosable={false}
    closeModal={null}
    data-testid="fraudModal"
    primaryButton={{
      href: `${links.profile.personalInfo}?solvvyWF=a14e6f27-7e6b-4c2b-9e35-1b883ca5497e`,
      title: messages.buttonTitle,
    }}
  />
)


export default FraudModal
