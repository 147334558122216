export default {
  title: {
    en: 'Something went wrong',
  },
  text: {
    en: `
      We're having technical issues at that moment.<br /><br />Please try again later or contact our customer support
      at <a href="mailto:support@scentbird.com">support@scentbird.com</a>
    `,
  },
}
