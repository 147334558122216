import React from 'react'
import { useUser } from 'modules/user'
import { useSubscription } from 'modules/subscription'
import { useCnstrcTrackUserData } from 'modules/constructorIO'

import {
  useDataLayerSetup,
  useMixpanelSetup,
  useSentrySetup,
  useSyncGrowthBookAttributes,
  useRudderstackSetup,
} from './util'


const AnalyticsSetup: React.FC = () => {
  const { user, country, growthBookUid, isFetching: isUserFetching } = useUser()
  const { subscription, isFetching: isSubscriptionFetching } = useSubscription()

  const hookProps = {
    user,
    subscription,
    isFetching: isUserFetching || isSubscriptionFetching,
  }

  useSentrySetup(hookProps)
  useMixpanelSetup(hookProps)
  useRudderstackSetup(hookProps)
  useDataLayerSetup(hookProps)
  // ConstructorIO user data tracking
  useCnstrcTrackUserData(user)
  // ATTN we don't need it right now
  // useAttentiveSetup(hookProps)
  useSyncGrowthBookAttributes({ user, country, growthBookUid, isFetching: isUserFetching })

  return null
}


export default React.memo(AnalyticsSetup)
