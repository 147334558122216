import { useQuery } from 'apollo-client'
import { usePathname } from 'router'
import { useFt } from 'hooks'
import { constants } from 'helpers'

import pageSeosStrapiQuery from './graph/pageSeos.strapi.graphql'


const useStrapiSeo = () => {
  const pathname = usePathname()
  const isSeoStrapiEnabled = useFt(constants.features.seoStrapi)

  const { data } = useQuery(pageSeosStrapiQuery, {
    client: 'strapi',
    fetchPolicy: 'cache-first',
    variables: {
      filters: {
        url: {
          eq: pathname,
        },
      },
    },
    skip: !isSeoStrapiEnabled,
    context: {
      ssrCache: true,
    },
  })

  return data?.pageSeos?.data?.[0]?.attributes?.seo
}

export default useStrapiSeo
