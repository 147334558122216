import { openModal } from 'modals'
import { buttonMessages } from 'components/inputs'

import messages from './messages'


type ErrorModalProps = {
  message: string | Intl.Message
  traceId?: string
}

export const getTraceIdMessage = (traceId?: string) => {
  if (!traceId) {
    return null
  }

  return (
    <span className="text-p5 text-gray-70">TraceID: {traceId}</span>
  )
}

export const openErrorModal = (props: ErrorModalProps) => {
  const message = props.message

  const closeModal = openModal('commonModal', {
    title: messages.userError.title,
    text: message,
    children: getTraceIdMessage(props.traceId),
    primaryButton: {
      title: buttonMessages.ok,
      onClick: () => {
        closeModal()
      },
    },
    'data-testid': 'errorModal',
  })
}

export const openFraudModal = () => openModal('fraudModal')

type ServerErrorModalProps = {
  traceId?: string
}

export const openNetworkErrorModal = (props: ServerErrorModalProps = {}) => {
  const closeModal = openModal('commonModal', {
    title: messages.networkError.title,
    text: messages.networkError.text,
    children: getTraceIdMessage(props.traceId),
    primaryButton: {
      title: buttonMessages.ok,
      onClick: () => {
        closeModal()
      },
    },
    'data-testid': 'errorModal',
  })
}

export const openServerErrorModal = (props: ServerErrorModalProps = {}) => {
  const closeModal = openModal('commonModal', {
    title: messages.serverError.title,
    text: messages.serverError.text,
    children: getTraceIdMessage(props.traceId),
    primaryButton: {
      title: buttonMessages.ok,
      onClick: () => {
        closeModal()
      },
    },
    'data-testid': 'errorModal',
  })
}

export const openChangePlanErrorModal = (action?: 'downgrade' | 'upgrade') => {
  let title

  if (action === 'downgrade') {
    title = messages.changePlanError.titleDowngrade
  }
  else if (action === 'upgrade') {
    title = messages.changePlanError.titleUpgrade
  }
  else {
    title = messages.changePlanError.titleDefault
  }

  const closeModal = openModal('commonModal', {
    title,
    text: messages.changePlanError.text,
    primaryButton: {
      title: buttonMessages.gotIt,
      onClick: () => {
        closeModal()
      },
    },
    'data-testid': 'errorModal',
  })
}
