import React from 'react'

import type { NotificationProps } from 'components/feedback'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'

import messages from './messages'


type SuccessNotificationProps = NotificationProps & {
  title?: Intl.Message | string
  text: Intl.Message | string
}

const SuccessNotification: React.FunctionComponent<SuccessNotificationProps> = ({ title, text, closeNotification }) => (
  <PlainNotification
    title={title || messages.title}
    text={text}
    closeNotification={closeNotification}
  />
)


export default SuccessNotification
