import { CausedError } from 'helpers'


export const getErrorInfo = (error?: Error | string) => {
  let message: undefined | string

  if (error instanceof Error) {
    message = error.stack

    if (error instanceof CausedError) {
      const cause = error.cause instanceof Error ? error.cause.stack : JSON.stringify(error, null, 2)

      message = `${message}\n\nCaused by:\n\n${cause}`
    }
  }
  else if (!message && error) {
    message = String(error)
  }

  return message
}
