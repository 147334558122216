import { useEffect, useMemo, useRef } from 'react'
import { dataLayer, getAnalyticsContext } from 'analytics'
import { usePathname } from 'router'
import { useApolloClient } from 'apollo-client'
import { GraphQLError } from 'helpers'

import pixelApplyQuery, { type PixelApplyVariables } from './graph/pixelApply.graphql'


const useDataLayerSetup = ({ user, isFetching }: AnalyticsSetup.HookProps) => {
  const apolloClient = useApolloClient()

  useEffect(() => {
    const applyPixel = async (input: PixelApplyVariables['input']) => {
      try {
        const result = await apolloClient.mutate({
          mutation: pixelApplyQuery,
          fetchPolicy: 'no-cache',
          variables: {
            input,
          },
        })

        if (result.errors) {
          throw new GraphQLError(result.errors)
        }

        return result.data
      }
      catch (error) {
        console.warn(error)
      }
    }

    // ATTN we use this helper in GTM
    window.marketingActions = {
      applyPixel,
    }
  }, [ apolloClient ])

  const pathname = usePathname()
  const paramsRef = useRef(null)

  // this workaround is required to avoid events duplication when params are changed
  paramsRef.current = useMemo(() => {
    if (isFetching) {
      return null
    }

    const { id, email, gender, analyticsMetadata } = user || {}
    const isLoggedIn = id !== null

    const lifetimeStatus = analyticsMetadata?.lifetimeStatus || 'UNREGISTERED'
    const subscriptionStatus = analyticsMetadata?.subscriptionStatus || 'LEAD'

    return {
      id,
      email,
      gender,
      isLoggedIn,
      subscriptionStatus,
      lifetimeStatus,
    }
  }, [ isFetching, user ])

  const { id, email } = user || {}

  useEffect(() => {
    if (id && email) {
      dataLayer.track('user found', {
        user: {
          email,
          id,
        },
      })
    }
  }, [ email, id ])

  useEffect(() => {
    if (!paramsRef.current) {
      return
    }

    dataLayer.track('pageView', {
      user: paramsRef.current,
    })

    const { page: pageName } = getAnalyticsContext()

    if (pageName) {
      dataLayer.push({ pageName })
    }
  }, [ pathname ])
}


export default useDataLayerSetup
