export default {
  modalTitle: {
    en: 'Sorry, this account has been flagged for fraudulent activity',
  },
  modalText: {
    en: 'It looks like this account has been tampered with, please contact Customer Support for steps to unblock your account.',
  },
  buttonTitle: {
    en: 'Help me restore my account',
  },
}
