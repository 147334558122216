import React from 'react'

import { Text, type TextColor } from 'components/dataDisplay'
import { Notification } from 'components/feedback'
import type { NotificationProps } from 'components/feedback'


type PlainNotificationProps = NotificationProps & {
  title: string | Intl.Message
  text: string | Intl.Message
  titleColor?: TextColor
  textColor?: TextColor
}

const PlainNotification: React.FunctionComponent<PlainNotificationProps> = (props) => {
  const { title, text, titleColor = 'gold-50', textColor, timeout, onClick, closeNotification, onClose } = props

  return (
    <Notification closeNotification={closeNotification} onClick={onClick} onClose={onClose} timeout={timeout}>
      <Text style="h8" message={title} color={titleColor} data-testid="title" />
      <Text className="mt-8" style="p4" message={text} color={textColor} html data-testid="text" />
    </Notification>
  )
}


export default PlainNotification
