import React from 'react'
import links from 'links'
import { Redirect } from 'router'
import { useSubscription } from 'modules/subscription'

import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'


type HasNeverSubscribedProps = {
  redirectTo?: string
}

const HasNeverSubscribed: React.CFC<HasNeverSubscribedProps> = ({ children, redirectTo }) => {
  const { subscription, isFetching } = useSubscription()

  if (isFetching) {
    return (
      <PageSkeleton />
    )
  }

  if (!subscription.hasNeverSubscribed) {
    return (
      <Redirect to={redirectTo || links.subscription.main} />
    )
  }

  return (
    <>{children}</>
  )
}


export default HasNeverSubscribed
