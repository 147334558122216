import * as Sentry from '@sentry/browser'
import { useOnce } from 'hooks'
import logger from 'logger'


const useSentrySetup = ({ user }: AnalyticsSetup.HookProps) => {
  const { id: userId, email, analyticsMetadata } = user || {}
  const mixpanelId = analyticsMetadata?.mixpanelClientId

  // setup user data to
  useOnce(() => {
    logger.debug(userId ? `User: ${userId}` : 'No user found')

    if (__CLIENT__) {
      const scope = Sentry.getCurrentScope()
      if (userId) {
        scope.setUser({
          id: String(userId),
          email,
          mixpanelId,
        })
      }
      else {
        scope.setUser(null)
      }
    }
  }, [ userId, email, mixpanelId ])
}


export default useSentrySetup
