import { useQuery } from 'apollo-client'

import inquireSubscriptionCouponQuery from './graph/inquireSubscriptionCoupon.graphql'


// returns applied coupon from user's session
const useInquireSubscriptionCoupon = ({ skip }: { skip?: boolean } = {}) => {
  const { data, isFetching } = useQuery(inquireSubscriptionCouponQuery, {
    errorPolicy: 'ignore',
    skip,
  })

  const inquireSubscriptionCouponCode = data?.purchaseInquiry?.data?.items?.subscription?.coupon?.code

  return {
    inquireSubscriptionCouponCode,
    isFetching,
  }
}


export default useInquireSubscriptionCoupon
