import { useEffect } from 'react'
import { useDevice } from 'device'
import cookieStorage from 'cookie-storage'
import logger from 'logger'
import { constants } from 'helpers'
import { mixpanel } from 'analytics'
import { useOnce } from 'hooks'
import { type AbFtData, useAbFt } from 'modules/user'

import useAnalyticsContextSetup from './useAnalyticsContextSetup'


// little helper to provide correct domain
const setCookie = (name: string, value: any, session: boolean = false) => {
  cookieStorage.setItem(name, value, {
    domain: '.scentbird.com',
    maxAge: session ? null : 50 * 365 * 24 * 60 * 60, // 50 years
  })
}

// identifies a user
const setupUser = ({ mixpanelClientId, email }: { mixpanelClientId: string, email: string }) => {
  if (mixpanelClientId) {
    mixpanel.identify(mixpanelClientId)
  }

  if (email) {
    mixpanel.peopleSet({ $email: email })
  }

  const distinctId = mixpanel.getDistinctId()

  setCookie('scnt_mixpanel_clientid', distinctId)
  setCookie('_mxpnl_id', distinctId)
  setCookie('_mxpnl_ref', document.referrer)

  logger.info(`[Mixpanel] identify %j`, { distinctId: distinctId, email })
}

export const unregisterAbTestProps = (abTests: AbFtData['abTests']) => {
  if (!window.mixpanel?.persistence?.props) {
    return
  }

  const mixpanelPropertiesNames = Object.keys(window.mixpanel.persistence.props).filter((propName) => propName.startsWith('ab '))
  const activeAbNames = Object.keys(abTests)

  mixpanelPropertiesNames.forEach((propName) => {
    if (!activeAbNames.includes(propName)) {
      mixpanel.unregister(propName)
    }
  })

  logger.info('[Mixpanel] Unregister ab tests')
}

export const registerAbTestProps = (abTests: AbFtData['abTests']) => {
  const props = Object.keys(abTests).reduce((acc, name) => {
    const { value, mixpanelProperty, enabled } = abTests[name]

    if (enabled) {
      acc[mixpanelProperty] = value
    }

    return acc
  }, {})

  mixpanel.register(props)

  logger.info(`[Mixpanel] Register ab tests %j`, props)
}


// device info
const setupDeviceProps = (device: DeviceContext) => {
  const { os, platform, browser, browserVersion, isBot, isMobile, isTablet } = device
  const mixpanelDevice = isTablet ? 'tablet' : (isMobile ? 'mobile' : 'desktop')

  const props = {
    '_Device': mixpanelDevice,
    '_OS': os,
    '_Platform': platform,
    '_Browser': browser,
    '_BrowserVersion': browserVersion,
    '_BOT': isBot,
  }

  mixpanel.register(props)

  logger.info(`[Mixpanel] Register device sp %j`, props)
}

// basic user props from user object and subscription
const setupUserProps = (superProperties: AnalyticsSetup.SuperProperties) => {
  mixpanel.register(superProperties)

  logger.info(`[Mixpanel] Register user sp %j`, superProperties)
}

// postClick init props
const setupPostClickSuperProps = () => {
  const abTrafficGroup = cookieStorage.getItem(constants.cookieNames.postClickGroup)

  if (abTrafficGroup) {
    const props = {
      abTrafficGroup,
    }

    mixpanel.register(props)

    logger.info(`[Mixpanel] Register postclick sp %j`, props)
  }
  else {
    mixpanel.unregister('abTrafficGroup')
  }
}

// helps to track and assign utm tags
const setupUtmSuperProps = () => {
  const params = new URLSearchParams(window.location.search)

  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')

  const utmParams = {}
  const firstParams = {}

  campaignKeywords.forEach((keyword) => {
    // get first value if it's a list
    const value = params.get(keyword)

    if (!value?.length) {
      return
    }

    utmParams[`${keyword} [last touch]`] = value
    firstParams[`${keyword} [first touch]`] = value
  })

  mixpanel.register(utmParams)
  mixpanel.peopleSet(utmParams)
  mixpanel.peopleSetOnce(firstParams)

  logger.info(`[Mixpanel] Register last touch utm %j`, utmParams)
  logger.info(`[Mixpanel] Register first touch utm %j`, firstParams)

  // additional url params
  const queryCoupon = params.get('cpn')

  if (queryCoupon) {
    mixpanel.register({ 'Query coupon': queryCoupon })
    logger.info(`[Mixpanel] set Query coupon %s`, queryCoupon)
  }
}

const getSearchInfo = (referrer: string) => {
  const getSearchEngine = (host: string) => {
    if (/google./.test(host)) {
      return 'google'
    }

    if (/bing.com/.test(host)) {
      return 'bing'
    }

    if (/yahoo.com/.test(host)) {
      return 'yahoo'
    }

    if (/duckduckgo.com/.test(host)) {
      return 'duckduckgo'
    }

    return null
  }

  try {
    if (!referrer) {
      return null
    }

    const url = new URL(referrer)
    const searchEngine = getSearchEngine(url.host)
    let searchKeyword = null

    if (searchEngine) {
      const param = (searchEngine !== 'yahoo') ? 'q' : 'p'
      const keyword = url.searchParams.get(param)

      if (keyword?.length) {
        searchKeyword = keyword
      }
    }

    return {
      domain: url.host,
      searchEngine,
      searchKeyword,
    }
  }
  catch (error) {
    logger.warn(error)
    return null
  }
}

// sync mixpanel client-side properties with cookie for server-side events
// fallback if script inside partytown is blocked
const syncSuperPropertiesCookie = () => {
  const referrer = document.referrer
  const { domain, searchEngine, searchKeyword } = getSearchInfo(referrer) || {}

  const cookieData = {
    '$screen_width': window.screen.width,
    '$screen_height': window.screen.height,
    '$referrer': referrer,
    '$referring_domain': domain,
    '$search_engine': searchEngine,
    'mp_keyword': searchKeyword,
    'webAppVersion': 'rebrand',
  }

  if (typeof window?.mixpanel?.get_property === 'function') {
    // if mixpanel isn't available, set
    const propertiesToSync = [
      '$device_id',
      '$initial_referrer',
      '$initial_referring_domain',
      '_Device',
      '_Platform',
      '_OS',
      '_Browser',
      '_BrowserVersion',
      'productsInQueueQty',
      'abTrafficGroup',
      'ab postclick version', // it isn't ab test from our splitbird, it's internal postClick thing
    ]

    propertiesToSync.forEach((key) => {
      cookieData[key] = window.mixpanel.get_property(key)
    })

    // keep the old name too
    cookieData['Products in Queue'] = cookieData['productsInQueueQty']
  }

  Object.keys(cookieData).forEach((key) => {
    if (cookieData[key] === undefined) {
      delete cookieData[key]
    }
  })

  setCookie(constants.cookieNames.mixpanelSuperProperties, cookieData, true)

  logger.info(`[Mixpanel] Sync super properties cookie: %j`, cookieData)
}

const useMixpanelSetup = (props: AnalyticsSetup.HookProps) => {
  const { user } = props
  const device = useDevice()
  const { abTests } = useAbFt()
  const superProperties = useAnalyticsContextSetup(props)

  const email = user?.email
  const mixpanelClientId = user?.analyticsMetadata?.mixpanelClientId

  // This hook creates cookie if mixpanel is blocked
  useEffect(() => {
    try {
      // setup if mixpanel is blocked
      syncSuperPropertiesCookie()
    }
    catch (error) {
      logger.error(error)
    }
  }, [])

  // This hook should handle user reinitialisation
  useOnce(() => {
    if (!__CLIENT__) {
      return
    }

    try {
      // ATTN it is inside onInit, because it requires mixpanel to be initialized
      mixpanel.onInit(() => {
        setupUser({
          mixpanelClientId,
          email,
        })

        unregisterAbTestProps(abTests)
        registerAbTestProps(abTests)
        setupDeviceProps(device)
        setupUtmSuperProps()
        setupPostClickSuperProps()
      })
    }
    catch (error) {
      logger.error(error)
    }
  }, [ email, mixpanelClientId, abTests, device ])

  // This hook syncs user and subscription properties. They can change during user's action,
  // that's why they are in a different hook
  useOnce(() => {
    if (!__CLIENT__) {
      return
    }

    try {
      mixpanel.onInit(() => {
        setupUserProps(superProperties)
        syncSuperPropertiesCookie()
      })
    }
    catch (error) {
      logger.error(error)
    }
  }, [ superProperties ])
}


export default useMixpanelSetup
