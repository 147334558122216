import logger from 'logger'

import isRichTextStateEmpty from 'modules/strapi/isRichTextStateEmpty'


const getRichTextStateFromMessage = (state: string): Strapi.RichTextState | null => {
  if (!state) {
    return null
  }

  try {
    const editorState = JSON.parse(state)

    const isEditorStateEmpty = isRichTextStateEmpty(editorState)

    return !isEditorStateEmpty ? editorState : null
  }
  catch (error) {
    logger.error(error)

    return null
  }
}

export default getRichTextStateFromMessage
