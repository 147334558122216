export default {
  userError: {
    title: {
      en: 'Error',
    },
  },
  serverError: {
    title: {
      en: 'Something went wrong',
    },
    text: {
      en: `
      We're having technical issues at that moment.<br /><br />Please try again later or contact our customer support
      at <a href="mailto:support@scentbird.com">support@scentbird.com</a>
    `,
    },
  },
  networkError: {
    title: {
      en: 'Network error',
    },
    text: {
      en: `
      Something is temporarily wrong with your network connection.<br /><br />Please make sure your are connected to the Internet
      and then reload your browser or contact our customer support at
      <a href="mailto:support@scentbird.com">support@scentbird.com</a>
    `,
    },
  },
  changePlanError: {
    titleDefault: {
      en: 'Please contact our Customer Support team to change plan',
    },
    titleDowngrade: {
      en: 'Please contact our Customer Support team to downgrade',
    },
    titleUpgrade: {
      en: 'Please contact our Customer Support team to upgrade',
    },
    text: {
      en: ' In order to change your subscription, please contact our Customer Experience team at <a href="mailto:support@scentbird.com">support@scentbird.com</a>',
    },
  },
}
