export default {
  addedToYourCart: {
    en: 'Added to your cart',
  },
  addedToYourQueue: {
    en: 'Added to your queue',
  },
  addedToCart: {
    en: 'Added to cart',
  },
  addedToQueue: {
    en: 'Added to queue',
  },
  multipleAdded: {
    en: '{count, plural, one {Product was} other {# products were}} added',
  },
  productName: {
    variety: {
      en: '{name} with {variety} Scent',
    },
    drift: {
      en: '{name} in {variety}',
    },
  },
}
