import React from 'react'
import loadable from '@loadable/component'
import { DynamicPageContentProvider, useDynamicPageContent } from 'modules/dynamicPages'

import type { ComponentPageLandingPageConfig } from 'typings/strapi.graphql'

import HasNeverSubscribed from 'containers/HasNeverSubscribed/HasNeverSubscribed'
import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'


const fallback = <PageSkeleton />

const Page404 = loadable(() => import('pages/Page404/Page404'), { fallback })
const StrapiLandingPage = loadable(() => import('pages/landing/StrapiLandingPage/StrapiLandingPage'), { fallback })

const MainLayout = loadable(() => import('layouts/MainLayout/MainLayout'), { fallback })
const LandingLayout = loadable(() => import('layouts/LandingLayout/LandingLayout'), { fallback })


const DynamicPageView: React.FC = () => {
  const dynamicData = useDynamicPageContent()

  if (dynamicData?.config?.some(({ __typename }) => __typename === 'ComponentPageLandingPageConfig')) {
    const config = dynamicData.config[0] as ComponentPageLandingPageConfig

    return (
      <LandingLayout forceFullLayout={config.layout === 'full'}>
        <HasNeverSubscribed redirectTo={config.redirectTo}>
          <StrapiLandingPage data={dynamicData} />
        </HasNeverSubscribed>
      </LandingLayout>
    )
  }

  return (
    <MainLayout contentWrapperClassName="flex">
      <Page404 />
    </MainLayout>
  )
}

const dynamicPageSkeletonNode = (
  <MainLayout>
    <PageSkeleton />
  </MainLayout>
)

const DynamicPage: React.FC = () => {
  return (
    <DynamicPageContentProvider skeletonNode={dynamicPageSkeletonNode}>
      <DynamicPageView />
    </DynamicPageContentProvider>
  )
}

export default DynamicPage
