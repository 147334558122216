import React from 'react'
import loadable from '@loadable/component'
import { Notifications as NotificationsConductor } from 'notifications'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'
import SuccessNotification from 'compositions/notifications/SuccessNotification/SuccessNotification'
import ErrorNotification from 'compositions/notifications/ErrorNotification/ErrorNotification'
import ServerErrorNotification from 'compositions/notifications/ServerErrorNotification/ServerErrorNotification'
import NetworkErrorNotification from 'compositions/notifications/NetworkErrorNotification/NetworkErrorNotification'
import ProductAddedNotification from 'compositions/notifications/ProductAddedNotification/ProductAddedNotification'
import ImageWithTextNotification from 'compositions/notifications/ImageWithTextNotification/ImageWithTextNotification'

import s from './Notifications.module.css'


export const templates = {
  'plain': PlainNotification,
  'success': SuccessNotification,
  'error': ErrorNotification,
  'serverError': ServerErrorNotification,
  'networkError': NetworkErrorNotification,
  'productAdded': ProductAddedNotification,
  'imageWithText': ImageWithTextNotification,
  'limitedDropNotification': loadable(() => import('compositions/notifications/LimitedDropNotification/LimitedDropNotification')),
} as const

const Notifications = () => (
  <NotificationsConductor
    className={s.notifications}
    templates={templates}
  />
)


export default Notifications
