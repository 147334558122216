import React from 'react'
import { Helmet } from 'react-helmet-async'


type StatusCodeProps = {
  value: number
}

const StatusCode: React.FunctionComponent<StatusCodeProps> = ({ value }) => (
  <Helmet htmlAttributes={{ statusCode: value }} />
)


export default StatusCode
