import React from 'react'
import { type ModalComponentProps } from 'modals'
import { useDevice } from 'device'
import { twcx } from 'helpers'

import { Text } from 'components/dataDisplay'
import { PlainModal } from 'components/feedback'


const CommonDrawerModal: React.CFC<ModalComponentProps & CommonDrawerModal.Props> = (props) => {
  const {
    className,
    overlayClassName,
    containerClassName,
    children,
    title, text,
    titleStyle = 'sh5',
    overlayClosable,
    closeModal,
    'data-testid': dataTestId,
    type = 'default',
    withCloseButton = true,
  } = props

  const { isMobile } = useDevice()
  const modalClassName = twcx(isMobile ? 'bg-light-beige min-h-[170rem]' : '', className)

  return (
    <PlainModal
      className={modalClassName}
      type={isMobile ? 'bottom' : type}
      closeModal={closeModal}
      overlayClosable={overlayClosable}
      data-testid={dataTestId}
      overlayClassName={overlayClassName}
      withCloseButton={withCloseButton}
      containerClassName={containerClassName}
    >
      <Text className="pr-48" message={title} style={titleStyle} html />
      {
        Boolean(text) && (
          <Text className="mt-16" message={text} style="p2" html />
        )
      }
      {children}
    </PlainModal>
  )
}


export default CommonDrawerModal
