import React, { useState, useMemo } from 'react'

import GlobalLoader from 'compositions/GlobalLoader/GlobalLoader'


type GlobalLoaderContextValue = {
  showLoader: () => void
  hideLoader: () => void
}

export const GlobalLoaderContext = React.createContext<GlobalLoaderContextValue | null>(null)

const GlobalLoaderProvider: React.CFC = ({ children }) => {
  const [ isVisible, setVisibility ] = useState(false)

  const value = useMemo(() => ({
    showLoader: () => setVisibility(true),
    hideLoader: () => setVisibility(false),
  }), [])

  return (
    <GlobalLoaderContext.Provider value={value}>
      {children}
      {isVisible && <GlobalLoader withOverlay />}
    </GlobalLoaderContext.Provider>
  )
}


export default GlobalLoaderProvider
