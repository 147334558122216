import localStorage from 'local-storage'


export const isExperimentTracked = (experimentKey: string): boolean => {
  const trackedExperiments = localStorage.getSessionItem('trackedExperiments') || []

  return trackedExperiments.includes(experimentKey)
}

export const markExperimentAsTracked = (experimentKey: string): void => {
  if (!isExperimentTracked(experimentKey)) {
    const trackedExperiments = localStorage.getSessionItem('trackedExperiments') || []

    trackedExperiments.push(experimentKey)
    localStorage.setSessionItem('trackedExperiments', trackedExperiments)
  }
}
