import React from 'react'

import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'

import useDynamicPageContentProvider, { type UseDynamicPageContentProviderProps } from './useDynamicPageContentProvider'
import { DynamicPageContentContext } from './contexts'


type DynamicPageContentProviderProps = UseDynamicPageContentProviderProps & {
  skeletonNode?: React.ReactNode
}

const DynamicPageContentProvider: React.CFC<DynamicPageContentProviderProps> = (props) => {
  const { children, ssr, skeletonNode } = props

  const { data, isFetching } = useDynamicPageContentProvider({ ssr })

  if (isFetching) {
    if (skeletonNode) {
      return skeletonNode
    }

    return (
      <PageSkeleton />
    )
  }

  return (
    <DynamicPageContentContext.Provider value={data}>
      {children}
    </DynamicPageContentContext.Provider>
  )
}

export default DynamicPageContentProvider
