import React from 'react'
import { useQuery } from 'apollo-client'

import GlobalLoader from 'compositions/GlobalLoader/GlobalLoader'
import initialDataQuery from './util/initialData.graphql'


// ATTN Initial data it's a first request. We should wait for it to execute to get a session for the user
const InitialData: React.CFC<{ isLightweightSSR?: boolean }> = ({ children, isLightweightSSR }) => {
  const { error, isFetching } = useQuery(initialDataQuery, {
    variables: {
      lightweight: false,
      abFt: false,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    ssr: !isLightweightSSR,
  })

  if (isFetching) {
    return (
      <GlobalLoader />
    )
  }

  if (error) {
    throw error
  }

  return (
    <>{children}</>
  )
}


export default InitialData
