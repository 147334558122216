import { useEffect } from 'react'
import { useGrowthBook } from '@growthbook/growthbook-react'
import logger from 'logger'

import type { ModifiedUser } from 'modules/user'


type Props = {
  user: ModifiedUser
  growthBookUid: string
  country: string
  isFetching: boolean
}

const useSyncGrowthBookAttributes = (props: Props) => {
  const { user, country, growthBookUid, isFetching } = props

  const gb = useGrowthBook()
  const isNotReady = !gb?.ready || isFetching

  useEffect(() => {
    if (isNotReady) {
      return
    }

    try {
      void gb.updateAttributes({
        growthBookUid,
        id: user?.id,
        email: user?.email,
        loggedIn: Boolean(user?.id),
        country,
        ...user?.analyticsMetadata,
      })
    }
    catch (error) {
      logger.error(error)
    }

  }, [ user, gb, isNotReady, growthBookUid, country ])
}

export default useSyncGrowthBookAttributes
