import { useCallback } from 'react'
import { useFt } from 'hooks'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'


const useCookieCoupon = (defaultCoupon?: string) => {
  const isDefaultCoupon50Enabled = useFt(constants.features.defaultCoupon50)
  const isHoliday22Sub60Enabled = useFt(constants.features.holiday22Sub60)

  let fallbackCoupon

  if (isHoliday22Sub60Enabled) {
    fallbackCoupon = 'HD60'
  }
  else if (isDefaultCoupon50Enabled) {
    fallbackCoupon = 'HD50'
  }
  else {
    fallbackCoupon = 'HD25'
  }

  const realDefaultCoupon = typeof defaultCoupon === 'undefined' ? fallbackCoupon : defaultCoupon

  const getCookieCoupons = useCallback(() => {
    const cookieCouponCode = cookieStorage.getItem(constants.cookieNames.discountCoupon) // coupon from landings
    const appliedCouponCode = cookieStorage.getItem(constants.cookieNames.appliedCouponCode)

    const couponCode = cookieCouponCode === undefined ? realDefaultCoupon : cookieCouponCode // cookieCouponCode may be null for "no discount" flow

    return {
      couponCode, // coupon we should use for prices
      cookieCouponCode, // coupon which was saved previously
      appliedCouponCode, // coupon which was applied to the current  session
    }
  }, [ realDefaultCoupon ])

  const setCookieCoupon = useCallback((code: string) => {
    cookieStorage.removeItem(constants.cookieNames.appliedCouponCode)
    cookieStorage.setSessionItem(constants.cookieNames.discountCoupon, code)

    if (code === 'FREE_TRIAL') {
      cookieStorage.setSessionItem(constants.cookieNames.isFreeTrialCoupon, true)
    }
    else {
      cookieStorage.removeItem(constants.cookieNames.isFreeTrialCoupon)
    }
  }, [])

  const data = getCookieCoupons()

  return {
    ...data,
    getCookieCoupons,
    setCookieCoupon,
  }
}


export default useCookieCoupon
