import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { Logo } from 'components/dataDisplay'
import StatusCode from 'containers/StatusCode/StatusCode'
import PageError from 'compositions/PageError/PageError'
import ErrorTechnicalDetails from './components/ErrorTechnicalDetails/ErrorTechnicalDetails'

import messages from './messages'


type Page500Props = {
  error?: string | Error
  traceId?: string
}

const Page500: React.FunctionComponent<Page500Props> = (props) => {
  const { error, traceId } = props
  const { isMobile } = useDevice()

  return (
    <div className={cx('flex min-h-full flex-col items-center bg-light-beige', isMobile ? 'pt-24' : 'pt-48')}>
      <StatusCode value={500} />
      <Logo color="black" />
      <PageError className="my-auto" title={messages.title} text={messages.text}>
        {
          Boolean(traceId) && (
            <div className="mt-32 text-gold-70">TraceID: {traceId}</div>
          )
        }
      </PageError>
      <ErrorTechnicalDetails
        className="my-32"
        error={error}
        traceId={traceId}
      />
    </div>
  )
}

export default Page500
