import dayjs from 'date'

import type { PageContentPayload } from './graph/pageContent.strapi.graphql'


type DynamicPageData = PageContentPayload['pages']['data'][number]['attributes']

const modifyDynamicPageBodySection = (bodySection: DynamicPageData['bodySection']) => {
  const now = dayjs()

  return bodySection?.filter((component) => {
    const { __typename } = component

    if (__typename === 'ComponentPagePageNotification') {
      const { endsAfter, startsFrom } = component

      const isStarted = startsFrom ? now.isAfter(startsFrom) : true
      const isEnded = endsAfter ? now.isAfter(endsAfter) : false

      return isStarted && !isEnded
    }

    return true
  })
}

export type DynamicPageContentContextValue = ReturnType<typeof modifyDynamicPageContent>

const modifyDynamicPageContent = (data: DynamicPageData) => {
  if (!data) {
    return null
  }

  const { bodySection, heroSection, config } = data

  return {
    bodySection: modifyDynamicPageBodySection(bodySection),
    heroSection,
    config,
  }
}

export default modifyDynamicPageContent
