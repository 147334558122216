import { createContext, useContext, useMemo } from 'react'
import { useUser } from 'modules/user'

// Price channels for commerce-tools
export const priceChannels = {
  privateSale: 'flashsale',
  tossIn: 'toss-in',
  buyQueue: 'buy-queue',
  limitedDropSale: 'limited-drop-sale',
} as const

export type PriceChannel = typeof priceChannels[keyof typeof priceChannels]

export type PriceSelectionContextValue = {
  channel: PriceChannel
  country: string
}

const PriceSelectionContext = createContext<PriceSelectionContextValue>(null)

export const usePriceSelection = () => useContext(PriceSelectionContext)

export type PriceSelectionProps = {
  channel?: PriceChannel
}

export const PriceSelectionProvider: React.CFC<PriceSelectionProps> = (props) => {
  const { children, channel } = props
  const { country } = useUser()

  const value = useMemo<PriceSelectionContextValue>(() => ({
    country,
    channel,
  }), [ country, channel ])

  return (
    <PriceSelectionContext.Provider value={value}>
      {children}
    </PriceSelectionContext.Provider>
  )
}

