import React from 'react'
import { twcx } from 'helpers'

import { Notification, type NotificationProps } from 'components/feedback'
import { Label, Text, type TextStyle } from 'components/dataDisplay'

import ProductImage from 'compositions/ProductImage/ProductImage'


export type ImageWithTextNotificationProps = NotificationProps & {
  image: string
  imageClassName?: string
  label?: Intl.Message | string
  labelClassName?: string
  text: Intl.Message | string
  textStyle: TextStyle
  staticMode?: boolean
  underPopups?: boolean
}

const ImageWithTextNotification: React.FC<ImageWithTextNotificationProps> = (props) => {
  const { closeNotification, image, imageClassName, label, labelClassName, text, textStyle, onClose, staticMode, underPopups } = props

  return (
    <Notification
      className="bg-light-beige"
      closeNotification={closeNotification}
      timeout={staticMode ? null : undefined}
      withAnimation={!staticMode}
      underPopups={underPopups}
      onClose={onClose}
    >
      <div className="flex">
        <ProductImage
          className={twcx('-my-8 -ml-16 mr-16 size-80 flex-none self-center', imageClassName)}
          bgColor="light-beige"
          src={image}
          remWidth={80}
        />
        <div className="flex flex-col justify-center">
          {Boolean(label) && (
            <Label className={twcx('mb-12 w-fit', labelClassName)} title={label} bgColor="black" textColor="white" />
          )}
          <Text
            message={text}
            style={textStyle}
            html
          />
        </div>
      </div>
    </Notification>
  )
}

export default React.memo(ImageWithTextNotification)
