import React from 'react'
import links from 'links'
import { Redirect, useLocationState } from 'router'

import { useUser } from 'modules/user'


type HasAuthProps = {
  isRegisterFlow?: boolean
}

const HasAuth: React.CFC<HasAuthProps> = ({ children, isRegisterFlow = false }) => {
  const { currentUrl } = useLocationState()
  const { isLoggedIn, isFetching } = useUser()

  if (isFetching) {
    return null
  }

  if (!isLoggedIn) {
    const link = isRegisterFlow ? links.register : links.login
    const to = `${link}?redirect=${encodeURIComponent(currentUrl)}`

    return (
      <Redirect to={to} withSearch />
    )
  }

  return (
    <>{children}</>
  )
}


export default HasAuth
