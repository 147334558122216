import { useEffect, useMemo, useRef } from 'react'
import { usePathname } from 'router'
import { getAnalyticsContext, rudderstack } from 'analytics'
import { useDevice } from 'device'
import { useOnce } from 'hooks'


const useRudderstackSetup = ({ user, isFetching }: AnalyticsSetup.HookProps) => {
  const pathname = usePathname()
  const { isTablet, isMobile } = useDevice()

  const userId = user?.id || null
  const paramsRef = useRef(null)

  // this workaround is required to avoid events duplication when params are changed
  paramsRef.current = useMemo(() => {
    if (isFetching) {
      return null
    }

    const device = isTablet ? 'tablet' : (isMobile ? 'mobile' : 'desktop')
    const isLoggedIn = userId !== null

    return {
      userId,
      isLoggedIn,
      device,
    }
  }, [ isMobile, isTablet, isFetching, userId ])

  useOnce(() => {
    if (__CLIENT__) {
      rudderstack.identify(String(userId) || '', { userId })
    }
  }, [ userId ])

  useEffect(() => {
    if (!paramsRef.current) {
      return
    }

    const referrer = document.referrer ? document.referrer.replace(/https?:\/\/(www\.)?scentbird.com/, '') : 'direct'
    const { page, pageType } = getAnalyticsContext()

    rudderstack.page(pageType, page, {
      ...paramsRef.current,
      referrer: referrer,
      pathname,
      pageTitle: document.title,
      pageReferrer: document.referrer,
    })
  }, [ pathname ])
}


export default useRudderstackSetup
