import { useMemo } from 'react'
import links, { getLinkWithParams } from 'links'
import { usePathname } from 'router'
import { useSubscribeOffer } from 'modules/subscription'

import messages from './messages'


type ClientSeo = {
  title: Intl.Message
  description?: Intl.Message
  keywords?: string
  image?: string
}

type SeoData = {
  [key: string]: ClientSeo
}

const seoData: SeoData = {
  [links.clean.perfumes]: messages.cleanPerfumes,
  [links.newArrivals.perfumes]: messages.newArrivalsPerfumes,
  [links.giftSets.perfumes]: messages.giftSetsPerfumes,
  [links.giftSets.colognes]: messages.giftSetsColognes,
  [links.giftSets.unisex]: messages.giftSetsUnisex,
  [links.terms]: messages.terms,
  [links.privacy]: messages.privacy,
  [links.press]: messages.press,
  [links.team]: messages.team,
  [links.cardUpdate]: messages.cardUpdate,
  [links.shop.samples.perfumes]: messages.shopSamplePerfumes,
  [links.shop.samples.colognes]: messages.shopSampleColognes,
  [links.giftSubscription]: messages.giftSubscription,
  [links.bestsellers.perfumes]: messages.bestsellersPerfumes,
  [links.subscription.perfumeOfTheMonthList]: messages.subscriptionPerfumeOfTheMonthList,
  [links.collectionsWomen]: messages.collectionsWomen,
}
const useClientSeo = (): ClientSeo => {
  const pathname = usePathname()
  const { formattedPrice: price = '' } = useSubscribeOffer()
  const pageSeoData = seoData[pathname]

  return useMemo(() => ({
    title: pageSeoData?.title || { ...messages.initialSeo.title, values: { price } },
    description: pageSeoData?.description || { ...messages.initialSeo.description, values: { price } },
    keywords: pageSeoData?.keywords || '',
    image: 'https://cdn.scentbird.com/og/cover.png',
  }), [ pageSeoData, price ])
}

export default useClientSeo
