export default {
  offer: {
    en: 'Spend {price} or more and receive a free <span class="text-gold-50 font-bold">{promoName}</span>.',
  },
  mothersDay: {
    en: 'Spend {price} or more and get a luxe travel bag plus nine fragrance and beauty samples.',
  },
  fathersDay: {
    en: `Spend over {price} and unlock a FREE 1.5 ml trio of Father's Day favorites.`,
  },
}
