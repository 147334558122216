import type { IncomingMessage, ServerResponse } from 'http'


export const mapError = (err: Error) => {
  if (!(err instanceof Error)) {
    return err
  }

  const res: any = {
    type: err.name,
    message: err.message,
    stack: err.stack,
  }

  if (err.hasOwnProperty('cause')) {
    const cause = mapError(err.cause as Error)
    if (cause) {
      res.cause = cause
    }
  }

  return res
}

export const mapHttpRequest = (req: IncomingMessage) => {
  return {
    method: req.method,
    url: req.url,
    // @ts-ignore
    hostname: req.hostname,
    // headers: req.headers,
    remoteAddress: req.socket.remoteAddress,
    remotePort: req.socket.remotePort,
  }
}

// fastify response
export const mapHttpResponse = (res: ServerResponse) => {
  return {
    statusCode: res.statusCode,
  }
}
