export default {
  title: {
    en: 'Network error',
  },
  text: {
    en: `
      Something is temporarily wrong with your network connection.<br /><br />Please make sure your are connected to the Internet
      and then reload your browser or contact our customer support at
      <a href="mailto:support@scentbird.com">support@scentbird.com</a>
    `,
  },
}
