import React from 'react'

import { Bone, PageLayout, WidthContainer } from 'components/layout'


const PageSkeleton = () => (
  <PageLayout>
    <WidthContainer>
      <Bone pw={100} h={100} />
    </WidthContainer>
  </PageLayout>
)


export default PageSkeleton
