import React, { useCallback } from 'react'
import cx from 'classnames'
import { openCartModal } from 'modules/cart'

import type { DriftOption, TradingItemVariety } from 'typings/graphql'

import { Text } from 'components/dataDisplay'
import { Notification } from 'components/feedback'
import type { NotificationProps } from 'components/feedback'

import ProductImage from 'compositions/ProductImage/ProductImage'
import SuccessNotification from 'compositions/notifications/SuccessNotification/SuccessNotification'

import messages from './messages'


export type ProductAddedNotificationProps = NotificationProps & {
  to: 'queue' | 'cart'
  count?: number // 1 or more
  brand?: string
  name?: string
  drift?: DriftOption
  variety?: TradingItemVariety
} & AllOrNothing<{
  image: string
  rebrandImage: string
}>

const ProductAddedNotification: React.FunctionComponent<ProductAddedNotificationProps> = (props) => {
  const { closeNotification, to, count = 1, brand, name, image, rebrandImage, drift, variety } = props
  const { description: driftDescription, image: driftImage, name: driftName } = drift || {}
  const { name: varietyName } = variety || {}

  const hasDrift = Boolean(drift)
  const hasVariety = Boolean(varietyName)

  const productImage = driftImage || rebrandImage || image
  const brandLine = driftName || brand
  const driftNameLine = hasDrift && hasVariety && { ...messages.productName.drift, values: { name: driftDescription, variety: varietyName } }
  const varietyNameLine = hasVariety && { ...messages.productName.variety, values: { name, variety: varietyName } }
  const nameLine = driftNameLine || varietyNameLine || name

  const isAddedToCart = to === 'cart'

  const handleClick = useCallback(() => {
    if (isAddedToCart) {
      openCartModal({
        flow: 'Product added notification',
      })
    }
  }, [ isAddedToCart ])

  if (count > 1 || !name) {
    const title = isAddedToCart ? messages.addedToCart : messages.addedToQueue
    const text = {
      ...messages.multipleAdded,
      values: {
        count,
      },
    }

    return (
      <SuccessNotification
        title={title}
        text={text}
        onClick={handleClick}
        closeNotification={closeNotification}
      />
    )
  }

  return (
    <Notification closeNotification={closeNotification} onClick={handleClick}>
      <div className="flex">
        {
          Boolean(productImage) && (
            <ProductImage
              className={cx('-my-8 -ml-16 mr-16 h-[80rem] w-80 flex-none')}
              src={productImage}
              alt={`${brand} ${name}`}
              remWidth={80}
            />
          )
        }
        <div className="flex-1">
          {
            Boolean(brandLine) ? (
              <>
                <Text style="h8" message={brandLine} color="gold-50" data-testid="brand" />
                <Text
                  className="mt-8"
                  style="p4"
                  message={nameLine}
                  data-testid="name"
                />
              </>
            ) : (
              <Text style="h8" message={nameLine} color="gold-50" data-testid="name" />
            )
          }
          <Text
            className="mt-8"
            style="p5"
            message={isAddedToCart ? messages.addedToYourCart : messages.addedToYourQueue}
            data-testid="text"
          />
        </div>
      </div>
    </Notification>
  )
}


export default ProductAddedNotification
