export default {
  title: {
    en: 'Please reload your page',
  },
  text: {
    en: `We've made a few updates to the site! Make sure to save all of your unsaved information, then reload your page to see the changes.`,
  },
  primaryButtonTitle: {
    en: 'Reload page',
  },
  secondaryButtonTitle: {
    en: 'Later',
  },
}
