import React from 'react'
import { useSearchParams } from 'router'

import AiSummaryStaticNotification from 'compositions/staticNotifications/AiSummaryStaticNotification/AiSummaryStaticNotification'
import GiftWithSpendThresholdStaticNotification from 'compositions/staticNotifications/GiftWithSpendThresholdStaticNotification/GiftWithSpendThresholdStaticNotification'


const StaticNotifications: React.FC = () => {
  const [ { autoTestSkip } ] = useSearchParams()

  if (autoTestSkip) {
    return null
  }

  // TODO: Should we allow more than one static notification? - added on 2024-08-26 by girilloid
  return (
    <>
      <AiSummaryStaticNotification />
      <GiftWithSpendThresholdStaticNotification />
    </>
  )
}

export default StaticNotifications
