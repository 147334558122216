import React from 'react'

import type { NotificationProps } from 'components/feedback'

import PlainNotification from 'compositions/notifications/PlainNotification/PlainNotification'

import messages from './messages'


const ServerErrorNotification: React.FunctionComponent<NotificationProps> = ({ closeNotification }) => (
  <PlainNotification
    title={messages.title}
    text={messages.text}
    closeNotification={closeNotification}
  />
)


export default ServerErrorNotification
