import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import analyticsAdditionalDataQuery from './graph/analyticsAdditionalData.graphql'


const useAdditionalData = (isLoggedIn?: boolean) => {
  const { data } = useQuery(analyticsAdditionalDataQuery, {
    skip: !isLoggedIn,
  })

  return useMemo(() => {
    const result = {
      scentProfile: undefined,
      fragranceFamily: undefined,
    }

    if (data?.quizResult?.representation) {
      result.scentProfile = data.quizResult.representation.scentProfileTitle
      result.fragranceFamily = data.quizResult.representation.fragranceFamily?.map(({ name }) => name).join(', ')
    }

    return result
  }, [ data ])
}

export default useAdditionalData
