import React from 'react'
import cx from 'classnames'

import { Overlay } from 'components/layout'
import s from './GlobalLoader.module.css'


type GlobalLoaderProps = {
  withOverlay?: boolean
}

const GlobalLoader: React.FC<GlobalLoaderProps> = ({ withOverlay = false }) => {
  const icon = (
    <svg
      className={cx(s.loader, 'm-auto')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3580 3628"
    >
      <defs>
        <mask id="GlobalLoader-Mask-bEnd" style={{ maskType: 'alpha' }}>
          <g transform="matrix(3.95 0 0 3.95 64.5 6.5)">
            <g opacity="1">
              <path
                fill="red"
                d="M475.2 272.5c124.1-64.8 72-259.1-99.1-259.1h-85.8c8.4 3.1 27.3 11.2 46.4 23.8 19.8 13 42.1 32 63 54.8a72.3 72.3 0 0 1 55.7 71.4c0 31.6-17.8 77-84
                77H250.2v72h132.2c76.3 0 101 53 101 89.8 0 41.4-23.2 88-88.7 93.3a195.3 195.3 0 0 1-91.7 76.1h82.6c195.6 0 254.7-229.6 89.7-299.1Z"
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
          </g>
        </mask>
        <mask id="GlobalLoader-Mask-bStart" style={{ maskType: 'alpha' }}>
          <g transform="translate(719.082,1082) scale(3.95,3.95) translate(-165.97,-272.405)">
            <g opacity="1">
              <path
                fill="#000000"
                d="M254.146,240.432C254.146,240.432,254.146,125.285,254.146,125.285C237.376,116.671,219.439,
                112.274,195.589,112.554C186.076,112.666,175.685,114.311,166.135,117.97C166.135,117.97,166.135,462.086,166.135,
                462.086C183.789,467.769,202.16,469.317,222.638,466.925C233.042,465.709,244.133,
                462.255,254.146,456.577C254.146,456.577,254.146,312.446,254.146,312.446C254.146,312.446,254.146,240.432,254.146,240.432Z"
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
          </g>
        </mask>
        <mask id="GlobalLoader-Mask-sDown" style={{ maskType: 'alpha' }}>
          <g transform="matrix(3.95 0 0 3.95 64 6)">
            <g opacity="1">
              <path
                fill="red"
                d="M379.1 330.4H272.2V352a125 125 0 0 1 9.4 7c49.5 40.3 15.6
              117.4-56.9 125.9-63.8 7.5-110.4-19.7-165.4-80.2l-59 59.7C40 514 110.2 575 218.3 571.5c134-4.4 218.5-119 169.2-225.8-2.5-5.4-5.3-10.4-8.5-15.3Z"
                fillOpacity="1"
                fillRule="nonzero"
              />
            </g>
          </g>
        </mask>
        <mask id="GlobalLoader-Mask-sUp" style={{ maskType: 'alpha' }}>
          <g transform="matrix(3.95 0 0 3.95 63.9 6.2)">
            <g opacity="1" transform="translate(203.4 152.7)">
              <path
                fill="#131414"
                d="M183.3-47.7C144-89.6 75-152.4-30.4-136.9-143.2-120.3-177.9-49.4-180.6-2.8c-2.7 47.2 9.5 92.8
                78 132.2a384.8 384.8 0 0 0 47.3 23v-92A75.8 75.8 0 0 1-69 50.3C-110.2 10.8-82.8-57.3-8-58.1 52-58.8 80.4-32.3 127 8.4l56.2-56Z"
                fillOpacity="1"
                fillRule="evenodd"
              />
            </g>
          </g>
        </mask>
      </defs>
      <g opacity="1" transform="translate(604 650)">
        <g mask="url(#GlobalLoader-Mask-bEnd)" opacity="1">
          <g opacity="1">
            <g transform="translate(1190 1164)">
              <g opacity="1">
                <path
                  fill="red"
                  d="M1198.5-1198.5v2397h-2397v-2397h2397Z"
                  fillOpacity="0"
                  fillRule="nonzero"
                />
              </g>
              <g opacity="1">
                <path
                  className={cx(s.animated, s.bEndDown)}
                  fill="none"
                  d="M-174-8c138 0 559.5-4 723.7 60.2C764 136 885.9 200.2 922.5 330 961 466 936 648.3 808.2 770.4 644.4 926.7 462 1001 42 1004"
                  stroke="#131414"
                  strokeOpacity="1"
                  strokeWidth="400"
                  strokeMiterlimit="4"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeDasharray="2512"
                  strokeDashoffset="2512"
                />
              </g>
            </g>
            <g transform="translate(1190 1124)">
              <g opacity="1">
                <path
                  fill="red"
                  d="M1156-1156v2312h-2312v-2312h2312Z"
                  fillOpacity="0"
                  fillRule="nonzero"
                />
              </g>
              <g opacity="1">
                <path
                  className={cx(s.animated, s.bEndUp)}
                  fill="none"
                  d="M-176-80s34 0 496-6c277-55 549-215 535.5-430.6C841.9-732.1 638.9-975-2-964"
                  stroke="#131414"
                  strokeOpacity="1"
                  strokeWidth="400"
                  strokeMiterlimit="4"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeDasharray="2304"
                  strokeDashoffset="2304"
                />
              </g>
            </g>
          </g>
        </g>
        <g opacity="1" transform="translate(1190,1164) translate(-1190,-1164)">
          <g mask="url(#GlobalLoader-Mask-bStart)">
            <g
              className={cx(s.animated, s.bStartMask)}
              transform="translate(698,1082) translate(500,82)"
            >
              <g opacity="1">
                <path
                  fill="#ff0000"
                  d="M1269.5,-1269.5L1269.5,1269.5L-1269.5,1269.5L-1269.5,-1269.5L1269.5,-1269.5Z"
                  fillOpacity="0"
                  fillRule="nonzero"
                />
              </g>
              <g opacity="1">
                <path
                  className={cx(s.animated, s.bStart)}
                  fill="none"
                  d="M-310,-764C-310,-764,-306,776,-306,776"
                  stroke="#131414"
                  strokeOpacity="1"
                  strokeWidth="421"
                  strokeMiterlimit="4"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeDasharray="0 1540"
                  strokeDashoffset="-693"
                />
              </g>
            </g>
          </g>
        </g>
        <g mask="url(#GlobalLoader-Mask-sDown)" opacity="1">
          <g transform="translate(1190 1164)">
            <g opacity="1">
              <path
                fill="red"
                d="M1188-1188v2376h-2376v-2376h2376Z"
                fillOpacity="0"
                fillRule="nonzero"
              />
            </g>
            <g opacity="1">
              <path
                className={cx(s.animated, s.sDown)}
                fill="none"
                d="M-42 4c319 168.3 404.9 518 214.2 730.4C-27 956.2-520.5 1027.4-1016 534"
                stroke="#131414"
                strokeOpacity="1"
                strokeWidth="419"
                strokeMiterlimit="4"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeDasharray="2221"
                strokeDashoffset="2221"
              />
            </g>
          </g>
        </g>
        <g mask="url(#GlobalLoader-Mask-sUp)" opacity="1">
          <g transform="translate(1190 1164)">
            <g opacity="1">
              <path
                fill="red"
                d="M1166.5-1166.5v2333h-2333v-2333h2333Z"
                fillOpacity="0"
                fillRule="nonzero"
              />
            </g>
            <g opacity="1">
              <path
                className={cx(s.animated, s.sUp)}
                fill="none"
                d="M330-592C126-782-8-950-338-960c-358-10.8-522 256-514 444 6.1 144 98 378 386 378"
                stroke="#131414"
                strokeOpacity="1"
                strokeWidth="419"
                strokeMiterlimit="4"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeDasharray="2150"
                strokeDashoffset="0"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  if (withOverlay) {
    return (
      <Overlay className={cx(s.overlay, s.container, 'flex')}>
        {icon}
      </Overlay>
    )
  }

  return (
    <div className={cx(s.container, 'flex size-full')}>
      {icon}
    </div>
  )
}

export default GlobalLoader
