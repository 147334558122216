import React, { useEffect, useMemo, useRef } from 'react'
import links from 'links'
import localStorage from 'local-storage'
import { closeNotification, openNotification } from 'notifications'
import { usePathname } from 'router'

import { constants } from 'helpers'

import { useDevice } from 'device'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import image from './images/ai-generated.svg'

import messages from './messages'


const applicablePagePaths = [
  links.bestsellers.root,
  links.clean.root,
  links.collectionMen,
  links.collectionWomen,
  links.giftSets.root,
  links.newArrivals.root,
  links.scentbirdSelect,
  links.shop.samples.root,
  links.shop.travelSize.root,
  links.subscription.root,
]
  .map((link) => link.replace('(.*)', '').replace(':slug', ''))
  .join('|')

const applicablePagePathRegExp = new RegExp(`^(${applicablePagePaths})`)

const handleNotificationClose = () => {
  localStorage.setItem(constants.localStorageNames.aiSummaryShown, true)
}

const AiSummaryStaticNotification: React.FunctionComponent = () => {
  const notificationIdRef = useRef<number>(null)

  const path = usePathname()

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryNotificationFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryNotification)

  const isAiSummaryNotificationEnabled = isAiSummaryEnabled && isAiSummaryNotificationFeatureEnabled

  const { isMobile } = useDevice()

  const isApplicablePath = useMemo(() => {
    return applicablePagePathRegExp.test(path)
  }, [ path ])

  useEffect(() => {
    if (!isAiSummaryNotificationEnabled) {
      return
    }

    const notificationWasShown = localStorage.getItem(constants.localStorageNames.aiSummaryShown)

    if (notificationWasShown) {
      return
    }

    if (!isApplicablePath) {
      if (notificationIdRef.current) {
        closeNotification(notificationIdRef.current)
        notificationIdRef.current = null
      }

      return
    }

    if (notificationIdRef.current) {
      return
    }

    notificationIdRef.current = openNotification('imageWithText', {
      image,
      imageClassName: 'size-[44rem] self-start my-0',
      label: messages.label,
      text: messages.text,
      textStyle: 'p4',
      staticMode: true,
      underPopups: true,
      onClose: handleNotificationClose,
    })

  }, [ isAiSummaryNotificationEnabled, isApplicablePath, isMobile ])

  return null
}

export default AiSummaryStaticNotification
