import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { PageLayout, WidthContainer } from 'components/layout'
import { ErrorBlock } from 'components/feedback'

import honeyImage from './images/honey.png'


type PageErrorProps = {
  className?: string
  title?: Intl.Message | string
  text?: Intl.Message | string
}

const PageError: React.CFC<PageErrorProps> = (props) => {
  const { children, className, title, text } = props

  const { isMobile } = useDevice()

  return (
    <PageLayout className={cx(className, isMobile ? 'pt-40' : 'pt-80' )} withPaddingTop={false}>
      <WidthContainer className={cx('grid grid-cols-[repeat(auto-fit,minmax(49%,1fr))] items-center', isMobile ? 'gap-8' : 'gap-16' )}>
        <img className="w-full max-w-[500rem]" src={honeyImage} alt="Honey photo" />
        <div>
          <ErrorBlock title={title} text={text} />
          {children}
        </div>
      </WidthContainer>
    </PageLayout>
  )
}


export default PageError
