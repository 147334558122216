import links from 'links'

// [ from1, from2, ..., to ]
const rawRedirects: Array<string[]> = [
  [ '/subscription', '/subscription/main-(women|men)', links.subscription.main ], //moved to nginx
  [ '/profile/billing-and-shipping', links.profile.shipping ], //moved to nginx
  [ '/subscription/payment/coupon', links.checkout ], //moved to nginx
  [ '/upgrade', '/upgrade-3', '/upgrade-6', '/upgrade-12', '/upgrade-plans', '/upgrade-plan', '/prepaid-plans', '/profile/upgrade', links.upgradeProduct ], //moved to nginx
  [ '/card-update-4', '/card-update-lite', links.cardUpdate ], //moved to nginx
  [ '/subscription/extras', links.shop.scentbirdCases ], //moved to nginx
  [ '/onboarding-product', links.smartRecommendations ], //moved to nginx
  [ '/case', '/case-monthly', links.caseSubscription ], //moved to nginx
  [ '/flash-sale', links.privateSale ], //moved to nginx
  [ '/makeup', links.subscription.beauty ], //moved to nginx
  [ '/skincare', links.subscription.beauty ], //moved to nginx
  [ '/personal-care', links.subscription.beauty ], //moved to nginx
  [ '/makeup/:product', links.subscription.beauty ], //moved to nginx
  [ '/skincare/:product', links.subscription.beauty ], //moved to nginx
  [ '/personal-care/:product', links.subscription.beauty ], //moved to nginx
  [ '/subscription/smartsearch', links.subscription.perfumes ], //moved to nginx
  [ '/subscription/collections-women', links.collectionsWomen ], //moved to nginx
  [ '/subscription/collections-men', links.collectionsMen ], //moved to nginx
  [ '/giftcard', links.giftCard ], //moved to nginx
  [ '/profile/orders-status', links.profile.orderHistory ], //moved to nginx
  [ '/profile/password', links.profile.personalInfo ], //moved to nginx
  [ links.profile.root, links.profile.subscriptionStatus ], //moved to nginx
  [ '/giftset', links.gifts ],
  [ '/subscription/resubscribe', links.resubscribe ],

  // seo pages
  [ '/new-arrivals-(perfume|fragrance)(s)?', links.newArrivals.perfumes ], //moved to nginx
  [ '/new-arrivals/(fragrance)(s)?', links.newArrivals.perfumes ], //moved to nginx
  [ '/new-arrivals-cologne(s)?', links.newArrivals.colognes ], //moved to nginx
  [ '/best-selling-(perfume|fragrance)(s)?', '/bestsellers-women', links.bestsellers.perfumes ], //moved to nginx
  [ '/best-selling-cologne(s)?', '/bestsellers-men', links.bestsellers.colognes ], //moved to nginx
  [ '/(perfume|fragrance|cologne)(s)?-for-women', '/perfumes', '/fragrance', '/popupshop', links.subscription.perfumes ], //moved to nginx
  [ '/(perfume|fragrance|cologne)(s)?-for-men', '/colognes', links.subscription.colognes ], //moved to nginx
  [ '/spring-giveaway', '/discover/show/:id', links.subscription.main ],
  [ '/perfume/:slug/main', links.productPerfume ],
  [ '/candles/:slug', links.productCandle ],
  [ '/hand-cream/:slug', '/ecommerce/personal-care/hand-cream/:slug' ],
  [ '/body', '/scrubs', '/lip-balms', '/hand-creams', links.shop.root ],
  [ '/:slug-collection', links.collectionsWomen ],
  [ '/collections-(women|men)/scent-horoscope', links.scentHoroscope ], // EP-2273

  // brand seo swarm PF-1050
  // ATTN param should be the same in from-to pair (e.g. :name as in links.brand)
  [ '/:name-fragrance(s)?', links.brand ], //moved to nginx
  [ '/:name-(perfume|fragrance|cologne)(s)?-for-(women|her)', links.seoPerfumes ], //moved to nginx
  [ '/:name-(perfume|fragrance|cologne)(s)?-for-(men|him)', links.seoColognes ], //moved to nginx

  // marketing links
  [ '/email/:page', links.home ], //moved to nginx
  [ '/how-it-works', links.home ], //moved to nginx
  [ '/influencer', links.home ], //moved to nginx
  [ '/men/influencer', links.homeMen ], //moved to nginx
  [ '/new', links.home ], //moved to nginx
  [ '/scentbird-reviews', links.home ], //moved to nginx

  // old lnks
  [ '/spring-giveaway', links.subscription.main ],
  [ '/giftset-women', links.giftSets.perfumes ],
  [ '/giftset-men', links.giftSets.colognes ],
]

type Redirect = {
  from: string
  to: string
}

// TODO add all redirects to nginx or to a middleware - added on 2022-01-26 by maddoger
const redirects = rawRedirects.reduce((result, item) => {
  const to = item[item.length - 1]
  for (let i = 1; i < item.length; i++) {
    result.push({
      from: item[i - 1],
      to,
    })
  }

  return result
}, [] as Redirect[])


export default redirects
